<template>
  <div class="relative flex items-center justify-center object-contain w-full h-full text-white">
    <div
      :id="containerId"
      class="bg-white container-transparent-grid-bg"
      :style="containerStyle"
    />

    <div class="absolute top-0 left-0 p-4">
      <div class="flex flex-col shadow-sm select-none">
        <button
          type="button"
          class="relative inline-flex items-center px-2 py-2 text-sm font-medium leading-5 text-gray-600 transition duration-150 ease-in-out bg-white border border-gray-300 rounded-t-md hover:text-gray-600 focus:z-10 focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-gray-100 active:text-gray-500"
          aria-label="Zoom In"
          @click.prevent="zoomIn"
        >
          <fa-icon icon="magnifying-glass-plus" />
        </button>
        <button
          type="button"
          class="relative inline-flex items-center px-2 py-2 -mt-px text-sm font-medium leading-5 text-gray-500 transition duration-150 ease-in-out bg-white border border-gray-300 rounded-b-md hover:text-gray-600 focus:z-10 focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-gray-300 active:text-gray-600"
          aria-label="Zoom Out"
          @click.prevent="zoomOut"
        >
          <fa-icon icon="magnifying-glass-minus" />
        </button>
      </div>
    </div>

    <PlayControls
      v-if="hasControl"
      :control="control"
    />
  </div>
</template>
<script>
// import PrevizComposition from '@previz/composition'
const PlayControls = () => import('./compositions/PlayControls')

export default {

  components: {
    PlayControls
  },
  props: {
    asset: {
      type: Object,
      required: true
    },
    dynamic: {
      type: Boolean,
      default: false
    }
  },

  // mounted() {
  //   // this.initViewer()
  //   // this.attachEventListeners()
  // },

  // beforeDestroy() {
  //   // this.dettachEventListeners()
  // },

  data () {
    return {
      zoom: 1,

      minZoom: 0.2,
      zoomStep: 0.2,

      control: null
    }
  },

  computed: {
    containerId () {
      return 'compositionContainer-' + this.asset.id
    },

    containerStyle () {
      return 'zoom: ' + this.zoom
    },

    hasControl () {
      return this.control !== null
    }
  },

  methods: {

    updateZoom (direction) {
      let updated = this.zoom

      if (direction === 'in') {
        updated = updated + this.zoomStep
      } else {
        updated = updated - this.zoomStep
      }

      if (updated >= this.minZoom) this.zoom = updated
    },

    zoomIn () {
      this.updateZoom('in')
    },

    zoomOut () {
      this.updateZoom('out')
    }

    // initViewer() {
    //  passing true as the 3rd param enabled edit controls
    // compositionviewer = new PrevizComposition(this.asset.data, this.containerId, this.dynamic)

    //   tach the viewer object to the window to allow others to inspect it
    //     y useful during development
    //  le    = 'previzcomposition'
    //  windo     = compositionviewer

    //  this.con     compositionviewer.core.control

    //  this.dispta    erReadyEvent()
    // },

    // disptachViewerReadyEvent() {
    //   var event = new CustomEvent('previz-viewer-attached', { detail: compositionviewer })
    //   window.dispatchEvent(event)
    // },

    // attachEventListeners ()
    //    window.addEventListener('previz-viewer-discover', this.handleDiscoverEvent)
    // },

    // dettachEventListeners ()
    //    window.removeEventListener('previz-viewer-discover', this.handleDiscoverEvent)
    // },

    // handleDiscoverEvent (ent) {
    //   let id = event.id

    //   if (id !== undefined && this.asset.id === id) {
    //     this.disptachViewerReadyEvent()
    //   }
    // }
  }
}
</script>
